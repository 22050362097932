import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Heading, Text } from "../../components"; // Adjust import paths as needed
import Header from "../../components/Header";
import Footer from "../../components/Footer";

// Reusable CountdownTimer component
function CountdownTimer({ targetDate }) {
  const [timeLeft, setTimeLeft] = useState(targetDate - Date.now());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(targetDate - Date.now());
    }, 1000);
    return () => clearInterval(timer);
  }, [targetDate]);

  if (timeLeft <= 0) {
    return <Text className="text-red-400">The competition has started!</Text>;
  }

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  return (
    <Text className="!text-black font-semibold text-right">
      Starts in:
      <br />
      {days}d {hours}h {minutes}m {seconds}s
    </Text>
  );
}

// Updated competitionsData with British Girl Makeup Contest FIRST
const competitionsData = [
  {
    title: "British Girl Makeup Contest",
    date: "January 26, 2025",
    image: "/images/britishgirlmakeup.png",
    targetDate: new Date("2025-01-26T19:00:00Z"), 
    steps: [
      <>
        Install{" "}
        <a
          href="https://apps.apple.com/us/app/testflight/id899247664"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          TestFlight
        </a>{" "}
        (Apple’s beta app tool).
      </>,
      <>
        Download{" "}
        <a
          href="https://testflight.apple.com/join/3bNkfMNT"
          className="text-blue-500 underline"
        >
          Pagent
        </a>{" "}
        on TestFlight.
      </>,
      <>
        Submit your video between <strong>2PM-4PM EST</strong>.
      </>,
      <>
        Vote between <strong>4PM-11:59PM EST</strong>.
      </>,
      <>
        Winner announced at midnight via Instagram:{" "}
        <strong>@pagent.app</strong>
      </>,
    ],
    // Updated prize details
    prize: "$500 for the winner<br /> $50 to 5 randomly selected voters",
  },
  {
    title: "Aaron Taylor-Johnson Look-Alike competition",
    prize:
      "$750 & a Costco hot dog for 1 winner <br /> $100 to 5 randomly selected voters",
    date: "February 2",
    image: "/images/aarontaylorjohnson.jpg",
    targetDate: new Date("2025-02-02T21:00:00Z"),
    steps: [
      <>
        Install{" "}
        <a
          href="https://apps.apple.com/us/app/testflight/id899247664"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          TestFlight
        </a>{" "}
        (Apple’s beta app tool).
      </>,
      <>
        Download{" "}
        <a
          href="https://testflight.apple.com/join/s9gfT6w5"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          Pagent
        </a>{" "}
        on TestFlight.
      </>,
      <>
        Submit your video between <strong>4PM-5PM est.</strong>
      </>,
      <>
        Vote between <strong>5PM-11:30PM est.</strong>
      </>,
      <>
        Winner announced at midnight via Instagram{" "}
        <a
          href="https://instagram.com/pagent.app"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          @pagent.app
        </a>
      </>,
    ],
  },
  {
    title: "McMaster University & Brock University Denim Outfit Competition",
    prize:
      "$500 to 1 Brock U winner<br /> $500 to 1 McMaster U winner<br /> $100 to 5 randomly selected random voter",
    date: "February 15, 2025",
    image: "/images/mcmasterbrock.png",
    targetDate: new Date("2025-02-015T21:00:00Z"),
    steps: [
      <>
        Install{" "}
        <a
          href="https://apps.apple.com/us/app/testflight/id899247664"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          TestFlight
        </a>{" "}
        (Apple’s beta app tool).
      </>,
      <>
        Download{" "}
        <a
          href="https://testflight.apple.com/join/BTTaWhm3"
          className="text-blue-500 underline"
        >
          Pagent
        </a>{" "}
        on TestFlight.
      </>,
      <>
        Submit your video between <strong>4PM-5PM est</strong>.
      </>,
      <>
        Vote between <strong>5PM-11:30PM est</strong>.
      </>,
      <>
        The <strong>winner</strong> will be announced at midnight via Instagram:{" "}
        <strong>@pagent.app</strong>
      </>,
    ],
  },
];

export default function CompetitionsPage() {
  const [scrolled, setScrolled] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const className = scrolled ? "scrolled" : "";

  return (
    <>
      <Helmet>
        <title>Competitions - Pagent Social Corp</title>
        <meta
          name="description"
          content="Explore all upcoming competitions on Pagent. Join look-alike, singing, and fashion contests to win prizes."
        />
      </Helmet>

      <div className="flex flex-col w-full min-h-screen bg-gradient3">
        <Header className={className} />
        <div className="flex flex-col items-center gap-10 pt-32 flex-grow">
          {/* Breadcrumb / Title */}
          <div className="container-xs flex flex-col items-start md:px-5">
            <Link to="/" className="no-underline">
              <Heading size="headingmd" as="h1" className="!font-medium">
                <span className="text-white-a700_99">Home &gt;</span>
                <span className="text-white-0">&nbsp;Competitions</span>
              </Heading>
            </Link>
          </div>

          <div className="container-xs flex flex-col gap-[100px] self-stretch md:gap-[75px] sm:gap-[50px] md:px-5 pb-20">
            {/* List of Competitions */}
            <div className="flex flex-col gap-6">
              {competitionsData.map((comp, index) => (
                <div
                  key={index}
                  className="bg-[#FFFFFF] rounded-lg shadow p-4 !text-black"
                >
                  {/* Top Row: Title, Image, Prize, etc. */}
                  <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                    <div className="flex items-start gap-4">
                      <img
                        src={comp.image}
                        alt={comp.title}
                        className="w-24 h-24 object-cover rounded-md"
                      />
                      <div className="flex flex-col justify-center">
                        <Heading as="h2" size="16_medium" className="!text-black">
                          {comp.title}
                        </Heading>
                        {/* Render the prize with line breaks */}
                        <Text as="p" className="!text-black">
                          <strong className="!text-black">Prize:</strong>
                          {comp.prize.split("<br />").map((segment, idx) => (
                            <React.Fragment key={idx}>
                              <br />
                              {segment}
                            </React.Fragment>
                          ))}
                        </Text>
                        <Text as="p" className="!text-black">
                          <strong className="!text-black">Date:</strong>{" "}
                          {comp.date}
                        </Text>
                        <button
                          onClick={() => handleToggle(index)}
                          className="mt-2 py-1 px-3 bg-[#E58B5E] text-white rounded-md w-fit"
                        >
                          {expandedIndex === index ? "Hide Steps" : "View Steps"}
                        </button>
                      </div>
                    </div>
                    {/* Countdown Timer */}
                    <div className="sm:ml-4 min-w-[120px]">
                      <CountdownTimer targetDate={comp.targetDate} />
                    </div>
                  </div>

                  {/* Steps Section */}
                  {expandedIndex === index && (
                    <div
                      className="mt-4 w-full p-4 rounded-md"
                      style={{ backgroundColor: "#2D2D2D" }}
                    >
                      <Heading
                        as="h3"
                        size="16_medium"
                        className="text-center mb-3 font-semibold text-white-0"
                      >
                        How to Participate or Vote
                      </Heading>
                      <div className="space-y-2 leading-6 text-white-a700_99">
                        {comp.steps.map((step, stepIdx) => (
                          <p key={stepIdx} className="break-words">
                            <strong className="text-white-0">
                              {stepIdx + 1}.
                            </strong>{" "}
                            {step}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <Footer className="w-full" />
      </div>
    </>
  );
}
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Button, Heading, Img } from "./.."; // Adjust if needed

export default function Header({ ...props }) {
  const [toggle, setToggle] = useState(false);

  const handleView = (id) => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header
      {...props}
      className={`${props.className} sm:py-[26px] md:py-[26px] lg:py-[26px] py-[15px] px-3 sm:px-3 md:px-0 lg:px-0 xl:px-0 transition-custom z-[99999] w-full fixed top-0 left-0`}
      style={{ backgroundColor: "rgba(211, 211, 211, 0.15)" }}
    >
      <div className="header-container container flex items-center justify-between w-full sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-10/12 mx-auto">
        
        {/* Left Side: Logo */}
        <a href="/" className="flex items-center space-x-2">
          <Img
            src="images/pagent-logo-icon.png"
            alt="Header Logo Icon"
            className="h-[40px] sm:h-[40px] md:h-[40px] lg:h-[50px] xl:h-[50px] w-[40px] sm:w-[40px] md:w-[40px] lg:w-[50px] xl:w-[50px] object-cover"
          />
          <Img
            src="images/pagent-logo-text.png"
            alt="Header Logo Text"
            className="h-[30px] sm:h-[40px] md:h-[40px] lg:h-[50px] xl:h-[50px] w-auto object-cover"
          />
        </a>
        {/* End Left Side: Logo */}

        {/* Desktop Nav */}
        <div className="md:flex justify-center items-center gap-x-5 mobile-device">
          <ul className="flex gap-x-5 items-center">
            <li>
              <a href="/">
                <Heading
                  size="headingxs"
                  as="h6"
                  style={{
                    fontFamily: "Avenir Next, sans-serif",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                  className="mr-4"
                >
                  Home
                </Heading>
              </a>
            </li>
            <li>
              <Button
                size="sm"
                variant="gradient"
                shape="round"
                color="yellow_linear_orange_600"
                className="min-w-[120px] h-[48px] font-bold"
                onClick={() =>
                  window.open("https://apps.apple.com/app/pagent/id6670614673", "_blank")
                }
              >
                Get the App
              </Button>
            </li>
          </ul>
        </div>

        {/* Mobile Hamburger Menu */}
        <div className="relative md:hidden">
          <button onClick={() => setToggle(!toggle)}>
            {toggle ? <IoClose size={25} color="#fff" /> : <FaBars size={25} color="#fff" />}
          </button>
          {toggle && (
            <div className="absolute -left-32 top-1 bg-white-0/75 w-32 rounded-lg">
              <ul className="flex flex-col gap-y-3 justify-center items-center py-4 text-center">
                <li>
                  <a href="/">
                    <Heading size="headingxs" as="h6" className="text-black">
                      Home
                    </Heading>
                  </a>
                </li>
                <li>
                  <Button
                    size="sm"
                    variant="gradient"
                    shape="round"
                    color="yellow_linear_orange_600"
                    className="text-sm w-full"
                    onClick={() =>
                      window.open("https://apps.apple.com/app/pagent/id6670614673", "_blank")
                    }
                  >
                    Get the App
                  </Button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
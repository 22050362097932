import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "components/Header";
import Footer from "components/Footer";

export default function McMasterBrockContest() {
  const [scrolled, setScrolled] = useState(false);

  // Countdown Timer for Jan 25, 2025 at 4:00 PM EST (21:00 UTC)
  const targetDate = new Date("2025-01-26T21:00:00Z").getTime();
  const [timeLeft, setTimeLeft] = useState(targetDate - Date.now());

  useEffect(() => {
    // Sticky header on scroll
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);

    // Update countdown every second
    const timer = setInterval(() => {
      setTimeLeft(targetDate - Date.now());
    }, 1000);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearInterval(timer);
    };
  }, [targetDate]);

  const className = scrolled ? "scrolled" : "";

  // Calculate time remain
  const hasExpired = timeLeft <= 0;
  let days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0;

  if (!hasExpired) {
    days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
    minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
    seconds = Math.floor((timeLeft / 1000) % 60);
  }

  return (
    <>
      <Helmet>
        <title>McMaster University and Brock University - Pagent</title>
        <meta
          name="description"
          content="McMaster University & Brock University outfit competition on Pagent"
        />
      </Helmet>

      <div className="flex w-full flex-col gap-[50px] bg-gradient3 min-h-screen pt-32">
        <Header className={className} />

        <div className="flex flex-col items-center gap-10 px-4">
          <div className="container-xs flex flex-col items-start w-full md:px-5">
            {/* Main box with #FFFFFF background */}
            <div className="flex flex-col gap-5 rounded-[24px] bg-[#FFFFFF] py-8 sm:py-5 w-full">
              <div className="flex flex-col items-center px-10 sm:px-5">

                {/* Image alone */}
                <img
                  src="/images/winteroutfit.png"
                  alt="Outfit Competition"
                  className="w-[400px] h-auto rounded mb-4"
                />


                {/* Subtitle in black */}
                {/* Reduced margin-bottom here from mb-2 to mb-1 */}
                <p className="text-center text-black text-lg font-bold mb-1">
             
                </p>

                {/* Steps to Participate (reduced the margin-top from mt-6 to mt-2) */}
                <div className="mt-2 w-full p-4 rounded-md" style={{ color: "black" }}>
                  <h3 className="text-center text-xl mb-3 font-semibold">
                    To Participate or Vote:
                  </h3>
                  <div className="space-y-2 lead-6">
                    <p style={{ color: "#333333" }}>
                      <strong style={{ color: "black" }}>1.</strong> Install{" "}
                     TestFlight (Apple’s beta app tool). <br />
                      <a
                        href="https://apps.apple.com/us/app/testflight/id899247664"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        Get TestFlight Here
                      </a>
                    </p>
                    <p style={{ color: "#333333" }}>
                      <strong style={{ color: "black" }}>2.</strong> Download{" "}
                      Pagent on TestFlight. <br />
                      <a
                        href="https://testflight.apple.com/join/BTTaWhm3"
                        className="text-blue-500 underline"
                      >
                        Download Pagent here once you have Testflight
                      </a>
                    </p>
                    <p style={{ color: "#333333" }}>
                      <strong style={{ color: "black" }}>3.</strong> Submit
                      your video between 4PM-5PM est
                    </p>
                    <p style={{ color: "#333333" }}>
                      <strong style={{ color: "black" }}>4.</strong> Vote
                      between 5PM-11:30PM est <br />
                    </p>
                    <p style={{ color: "#333333" }}>
                      The winner will
                      be announced at midnight via Instagram:{" "}
                      <strong>@pagent.app 😁🌟</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
          <Footer style={{ width: "100%" }} />
        </div>
      </div>
    </>
  );
}